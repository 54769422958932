import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main, buttonBack } from '../content/transport-branze'
import { useLangContext } from '../context/lang.context'
import { useFormContext } from '../context/form.context'
import { sCenteredSpaced } from '../style'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Tiles from '../components/Tiles'
import Button from '../components/Button'

const TransportBranze = () => {
  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()
  const { image, children } = useStaticQuery(graphql`
    {
      image: file(
        absolutePath: { regex: "/images/intros/transport-branze.jpg/" }
      ) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 80
            placeholder: DOMINANT_COLOR
            formats: [WEBP, AVIF]
          )
        }
      }
      children: allContentfulTransportBranza(sort: { fields: name }) {
        nodes {
          name
          slug
          node_locale
          higherorder
          image {
            gatsbyImageData(width: 480, quality: 80, formats: [WEBP, AVIF])
          }
        }
      }
    }
  `)

  const buttonSecond = {
    text: {
      pl: 'Zleć transport',
      en: 'Quote transport',
    },
    action: () => setFormOpen(true),
  }

  const links = children.nodes
    .filter((node) => node.node_locale === 'pl')
    .filter((node) => node.higherorder)
    .map((node) => ({
      slug: node.slug,
      name: node.name,
      image: node.image,
    }))

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/transport-branze.jpg'}
        url={seo.url}
      />
      <Intro data={{ ...intro, buttonSecond }} image={image.childImageSharp} />
      <Main data={main} h={1} />
      <Tiles data={links} />
      <div css={sCenteredSpaced}>
        <Button reactive link={buttonBack.link}>
          {buttonBack.text[lang]}
        </Button>
      </div>
    </Layout>
  )
}

export default TransportBranze
